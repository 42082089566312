import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AuthService } from '../services/auth.service';

@Injectable({providedIn: "root"})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):  boolean {
      if (this.authService.isLogin) {
        if(localStorage.getItem('role') === 'mentor'){
          if(localStorage.getItem('permissions').indexOf('passion_project') != -1){
            if((state.url.indexOf('passion-projects') !== -1) || (state.url.indexOf('report') !== -1) || (state.url.indexOf('guftagu') !== -1) || (state.url.indexOf('public-profile') !== -1) || (state.url.indexOf('public-profile') !== -1)  || (state.url.indexOf('zoom-integration') !== -1)){
              return true
            } else{
              this.router.navigate(['/passion-projects']);
              return false;
            }
          } else{
            this.router.navigate(['/passion-projects']);
            return false
          }
        }
        return true;
      } 
      this.authService.redirectUrl = state.url; 
      this.router.navigate(['/login'], { queryParams: { redirect: state.url } });
    return false;
      
  }
}