import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
//import { BulkUploadComponent } from './bulk-upload/bulk-upload.component';
// import { HeaderComponent } from './header/header.component';
// import { FooterComponent } from './footer/footer.component';
// import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
// import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
// import { ResetPasswordComponent } from './reset-password/reset-password.component';
// import { SearchComponent } from '../search/search.component';
import { FileUploadModule } from 'primeng/fileupload';
import { ProfileComponent } from './profile/profile/profile.component';
import { ChangePasswordComponent } from './profile/change-password/change-password.component';
import { AuthGuard } from './auth/auth.guard';

import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { NotificationService } from './services/notification.service';
import { HttpErrorInterceptor } from './services/httperror.interceptor';
import { JwtInterceptor } from './services/jwt.interceptor';
import { MyQuestionsComponent } from './questions/my-questions/my-questions.component';
// import { RecommendedQuestionsComponent } from './questions/recommended-questions/recommended-questions.component';
import { MyContentComponent } from './my-content/my-content.component';
import { QuestionDetailsComponent } from './questions/question-details/question-details.component';
import { ReportComponent } from './report/report.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
// import { ManageUserComponent } from './manage-user/manage-user.component';
// import { ManageRoleComponent } from './manage-role/manage-role.component';
import { FilterPipe } from './pipe/filter.pipe';
import { ShareService } from './services/share.service';
import { QuestionBankComponent } from './questionBank/question-bank/question-bank.component';
import { AddQuestionComponent } from './questionBank/add-question/add-question.component';
import { TinymceEditorComponent } from './questionBank/tinymce-editor/tinymce-editor.component';
import { MarkingComponent } from './marking/marking.component';
import { TagInputModule } from 'ngx-chips';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RetreiveAssessmentComponent } from './assessments/retreive-assessment/retreive-assessment.component';
import { CourseModule } from './courses/course.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { KeysPipe } from './pipe/keys.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PassionProjectsModule } from './passion-projects/passion-projects-module';
import { MobileUpgradeComponent } from './mobile-upgrade/mobile-upgrade.component';

import { EcUserProfileComponent } from './profile/ec-user-profile/ec-user-profile.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { ScheduleComponent } from './profile/schedule/schedule.component';
import { PersonalInfoComponent } from './profile/personal-info/personal-info.component';
import { MyPrComponent } from './profile/my-pr/my-pr.component';
import { MyRankComponent } from './profile/my-rank/my-rank.component';
import { AvatarLibraryComponent } from './profile/avatar-library/avatar-library.component';
import { SecuritySettingsComponent } from './profile/security-settings/security-settings.component';
import { AchievementComponent } from './profile/achievement/achievement.component';
import { SearchOrganisationComponent } from './add-organisation/search-organisation/search-organisation.component';
import { AddOrganisationComponent } from './add-organisation/add-organisation.component';

import { BsDatepickerModule, BsDatepickerConfig, BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { AppsSettingsComponent } from './profile/apps-settings/apps-settings.component';
import { CommonAppModuleModule } from './common-app-module/common-app-module.module';
import { ResourceService } from './services/resources/resource.service';
import { ResourceBankModule } from './resources/resource-bank-module';
import { NgxPaginationModule } from 'ngx-pagination';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatNativeDateModule,
  MatSelectModule,
  MatInputModule, 
  MatTooltipModule,
} from '@angular/material';
import { ManageCategoriesComponent } from './manage-categories/manage-categories.component';
import { ReportlistComponent } from './reportlist/reportlist/reportlist.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AskQuestionComponent } from './questions/ask-question/ask-question.component';
import { GiveYourAnswerComponent } from './questions/give-your-answer/give-your-answer.component';
// import { MyAnswersComponent } from './questions/my-answers/my-answers.component';
import { DateTimeAgoPipe } from './pipe/date-time-ago.pipe';
//import { CategoryListComponent } from './category-list/category-list.component';
// import { NotFoundResultComponent } from './not-found-result/not-found-result.component';
import { SplitTitlePipe } from './pipe/split-title.pipe';
import { ToFixedNumPipe } from './pipe/to-fixed-num.pipe';
// import { AllQuestionsComponent } from './questions/all-questions/all-questions.component';
import { splitComma } from './pipe/splitComma.pipe';
import { AnswerViewComponent } from './questions/answer-view/answer-view.component';
import { SearchComponent } from './search/search/search.component';
import { UiModule } from './ui/ui.module';
import { SharedModule } from './shared/modules/shared/shared.module';
import { ImportQuestionComponent } from './questionBank/import-question/import-question.component';
import { QuestionDetailComponent } from './questionBank/question-detail/question-detail.component';
import { ScheduleCalendarComponent } from './schedule-calendar/schedule-calendar.component';
import { QuestionBankModule } from './questionBank/question-bank-module';
import { TermsComponent } from './terms/terms.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ZoomIntegationComponent } from './zoom-integation/zoom-integation.component';

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
   // BulkUploadComponent,
    // HeaderComponent,
    // FooterComponent,
    // LoginComponent,
    RegistrationComponent,
    // ForgotPasswordComponent,
    // ResetPasswordComponent,
    //SearchComponent,
    ProfileComponent,
    ChangePasswordComponent,
    MyQuestionsComponent,
    // RecommendedQuestionsComponent,
    MyContentComponent,
    QuestionDetailsComponent,
    ReportComponent,
    ReportlistComponent,
    FilterPipe,
    PrivacyPolicyComponent,
    // ManageUserComponent,
    // ManageRoleComponent,
    // QuestionBankComponent,
    // ImportQuestionComponent,
    // QuestionDetailComponent,
    // AddQuestionComponent,
    TinymceEditorComponent,
    MarkingComponent,
    RetreiveAssessmentComponent,
    KeysPipe,
    MobileUpgradeComponent,
    EcUserProfileComponent,
    ScheduleComponent,
    PersonalInfoComponent,
    MyPrComponent,
    MyRankComponent,
    AvatarLibraryComponent,
    SecuritySettingsComponent,
    AchievementComponent,
    SearchOrganisationComponent,
    AddOrganisationComponent,
    AppsSettingsComponent,
    ManageCategoriesComponent,
    // NotFoundResultComponent,
    AskQuestionComponent,
    GiveYourAnswerComponent,
    // MyAnswersComponent,
    DateTimeAgoPipe,
    //CategoryListComponent,
    SplitTitlePipe,
    ToFixedNumPipe,
    // AllQuestionsComponent,
    splitComma,
    AnswerViewComponent,
    ScheduleCalendarComponent,
    TermsComponent,
    NotificationsComponent,
    ZoomIntegationComponent
      ],
  imports: [
    BrowserModule,
    UiModule,
    EditorModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    FileUploadModule,
    Ng2SearchPipeModule,
    NotifierModule.withConfig(customNotifierOptions),
    TagInputModule,
    BrowserAnimationsModule,
    PassionProjectsModule,
    ResourceBankModule,
    CourseModule,
    CommonAppModuleModule,
    QuestionBankModule,
    IvyCarouselModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    NgxPaginationModule,
    CarouselModule,
    SharedModule,
    MatTooltipModule,    
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    BsDatepickerModule.forRoot(),
  ],
  providers: [
    { provide: AuthGuard, useClass: AuthGuard },
    { provide: NotificationService, useClass: NotificationService },
    { provide: ShareService, useClass: ShareService },
    { provide: ResourceService, useClass: ResourceService },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    BsDaterangepickerConfig

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }