import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capEachWord'
})
export class CapEachWordPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return value;  // Return value if it's null or undefined

    // Split the string into words, capitalize each word, and join them back
    return value
      .split(' ')  // Split the string by spaces
      .map(word => 
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()  // Capitalize first letter and make the rest lowercase
      )
      .join(' ');  // Join the words back with spaces
  }

}
