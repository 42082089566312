<div class="new_UIstyle">
    <div class="pageBodyWrap videoWithNoteWrap" [style.minHeight]="!session_detail_data && loader ? '100vh': ''"
        [ngClass]="{submitting : loader}">
        <div class="spinner-border text-warning outer_loader_over" role="status" *ngIf="loader">
            <span class="sr-only">Loading...</span>
        </div>

        <section class="section videoWithNote-Section" *ngIf="session_detail_data">
            <div>

                <!-- video block start -->
                <div class="videoPlaylist-Wrap">
                    <div class="container">
                        <div class="backtosingle d-flex flex-row-reverse ">
                            <button type="button" class="btn btn-primary mb-1"
                                (click)="backToCourseDetails()">Back</button>
                        </div>
                        <div class="videoPlaylist-inn">
                            <div class="videoPlaylistElem videoLargeblock videoplayerBlock videoPlaylist-left">

                                <p class="avenirBlack title-video mb-0" [title]="session_detail_data.title">
                                    {{session_detail_data.title}}</p>
                                <div class="ratio ratio-16x9">
                                    <div class="h-100">
                                        <div class="h-100" *ngIf="get_s3_link()">
                                            <video crossorigin="anonymous" autoplay preload="metadata" width="540"
                                                height="310" controls id="videoByS3" #videoPlayer>
                                                <source [src]="url" type="video/mp4">
                                                <track id="mytrack" label="English Subtitles" [src]="vtt_file"
                                                    srclang="en" srclang="en" default>
                                            </video>
                                        </div>
                                        <div class="h-100" *ngIf="get_youtube_video()">
                                            <youtube-player autoplay [videoId]="url | youTubeVideoId"
                                                (ready)="savePlayer($event)" (stateChange)="onStateChange($event)"
                                                (playbackRateChange)="rate_change($event)" id="youtubeByS3">
                                            </youtube-player>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="videoPlaylistElem videoThumbnailblock videoPlaylist-right bg-grey">
                                <div class="videoPlaylist-title">
                                    <h6 class="avenirBlack" [title]="playlist_info.topic_title">
                                        {{playlist_info.topic_title}}</h6>
                                </div>

                                <div class="videoPlaylist-itemsWrap">
                                    <div class="learning-course-items-wrap" #scrollContainer>
                                        <div class="learning-course-item" [id]="'session-' + (i+1)"
                                            [ngClass]="(playlist_info._id ==  session_id) ? 'activeSessionItem': ''"
                                            *ngFor="let playlist_info of playlist_infoSession; let i = index">
                                            <a class="learning-course-link"
                                                [ngClass]="(playlist_info._id ==  session_id) ? 'activeSession': ''"
                                                (click)="playPlayList(playlist_info._id, playlist_info)">
                                                <div class="learning-course-elem-left">
                                                    <p class="learning-course-link-title">
                                                        <strong>{{playlist_info.title}}</strong></p>
                                                    <p
                                                        class="learning-course-link-subDes d-flex flex-wrap align-items-center">
                                                        <span class=""><img
                                                                src="../../../assets/images/apps-icon/video-icon.png"
                                                                alt="video-icon.png" /></span><small>{{playlist_info.duration}}</small>
                                                    </p>
                                                </div>
                                                <!-- <div class="learning-course-elem-right">
                                            <p class="course-status dark-green-text">Completed <i class="fas fa-check-double"></i></p>
                                        </div> -->
                                            </a>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Notes Tabs Start -->

                <div class="video-notes-tabs-wrap">
                    <div class="container">
                        <div class="video-notes-tabs">
                            <div class="video-notes-tabs-left">
                                <ul ngbNav #videoNotesTabs="ngbNav" [(activeId)]="active_id" (navChange)="onNavChange($event)"
                                    class="nav-tabs nav-tabs-custom">
                                    <li [ngbNavItem]="1">
                                        <a ngbNavLink (click)="openNote()">Notes</a>
                                        <ng-template ngbNavContent *ngIf="session_detail_data">
                                            <div class="nWV-tabs-content-block nWV-tabs-Notes-content">
                                                <div class="nWV-textareaBlock mb-5">
                                                    <app-note-taking [noteTake]="noteTake" [topic_id]="topic_id"
                                                        [session_detail_data]="session_detail_data"
                                                        (note_summary_updated)="update_note($event)" [player]="player"
                                                        (NoteAndSummryEditDetail)="NoteAndSummryEditDetail()">
                                                    </app-note-taking>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </li>

                                    <li [ngbNavItem]="2">
                                        <a ngbNavLink>Resources</a>
                                        <ng-template ngbNavContent>

                                            <div class="nWV-tabs-content-block nWV-tabs-Resources-content">
                                                <div class="learning-course-items-wrap learning-course-resources-wrap">
                                                    <div class="alert alert-info text-center"
                                                        *ngIf="session_detail_data.resources.length == 0">
                                                        <p>Resources are not available yet.</p>
                                                    </div>

                                                    <div class="learning-course-item"
                                                        *ngFor="let res of session_detail_data.resources;">
                                                        <div class="learning-course-link">
                                                            <div class="learning-course-elem-left">
                                                                <p class="learning-course-link-title">
                                                                    <span class="title-icon">
                                                                        <i class="fas fa-folder" *ngIf="res.type == 'file'"></i>
                                                                        <i class="fas fa-atlas" *ngIf="res.type == 'text'"></i>
                                                                    </span>
                                                                    <strong>{{res.title}}</strong>
                                                                </p>
                                                            </div>

                                                            <div class="learning-course-elem-right">
                                                                <a class="learning-course-download"
                                                                    (click)="downloadResource(res.url)">
                                                                    <i class="fas fa-download" *ngIf="res.type == 'file'"></i>
                                                                    <i class="fas fa-location-arrow" *ngIf="res.type == 'text'"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </li>

                                    <li [ngbNavItem]="3">
                                        <a ngbNavLink (click)="getCommentsInfo(session_id, courseId)">Comments</a>
                                        <ng-template ngbNavContent>

                                            <div class="nWV-tabs-content-block nWV-tabs-Resources-content">
                                                <div class="commentElemBlock">
                                                    <div class="writeCommentWrap">
                                                        <div class="commentleftElem">
                                                            <figure class="commentDp" *ngIf="avatarURL">
                                                                <img [src]="avatarURL" />
                                                            </figure>

                                                            <figure class="commentDp commentDp-sm commentDp-title"
                                                                *ngIf="!avatarURL">
                                                                {{first_name | slice:0:1}}
                                                            </figure>
                                                        </div>
                                                        <div class="commentRightElem mr-0">
                                                            <form id="commentVideo" #feedBack="ngForm"
                                                                (ngSubmit)="submitCourseFeedBack(feedBack, session_id)">


                                                                <textarea type="text"
                                                                    class="form-control form-control-textarea w-100"
                                                                    name="feedBack" ngModel
                                                                    placeholder="Add a comment... "
                                                                    (keyup.enter)="submitCourseFeedBack(feedBack, session_id)"></textarea>


                                                                <!-- <div class="btn-groups justify-content-end mt-4">
                                                            <button type="button" class="btn btn-sm btn-primary mr-3"
                                                                (click)="resetForm(feedBack)">Cancel</button>
                                                            <button type="submit" class="btn btn-sm btn-primary">Submit</button>
                                                        </div> -->
                                                            </form>
                                                        </div>
                                                    </div>


                                                    <div class="commentListElemWrap">
                                                        <!-- Comment Row start -->
                                                        <div class="commentElemrow"
                                                            *ngFor="let comment of feedBack_list">

                                                            <div class="commentleftElem commentUserDp">
                                                                <figure class="commentDp commentDp-sm"
                                                                    *ngIf="comment.profile_pic != ''">
                                                                    <img [src]="comment.profile_pic" />
                                                                </figure>
                                                                <figure class="commentDp commentDp-sm commentDp-title"
                                                                    *ngIf="comment.profile_pic == ''">
                                                                    {{comment.username | slice:0:1}}
                                                                </figure>
                                                            </div>
                                                            <div class="commentRightElem commentUserDetail">
                                                                <p>

                                                                    <small
                                                                        class="d-flex align-item-center commentUserhead">
                                                                        <span class="commentName">{{comment.username |
                                                                            titlecase}}</span>
                                                                        <span class="commentTime "> &nbsp; &#x2022;
                                                                            &nbsp;
                                                                            {{comment.timestamp
                                                                            |
                                                                            splitComma}} ago</span>
                                                                    </small>
                                                                    <span class="textComment"
                                                                        [innerHTML]="comment.comment"></span>

                                                                    <span class="commentUserFoot d-flex">
                                                                        <span
                                                                            class="mr-3 me-md-5 commentActionElem likeComment">
                                                                            <a class="commentActionhand"
                                                                                [ngClass]="comment.liked === true? 'active ' : 'inactive'"
                                                                                (click)="comments_like_liked(comment._id, 'liked', session_id,comment.liked)"><i
                                                                                    class="fas fa-thumbs-up mr-2"></i>
                                                                            </a>
                                                                            <b
                                                                                class="conunts black-text">{{comment.like_count}}</b>

                                                                        </span>
                                                                        <span class="commentActionElem disLikeComment">
                                                                            <a class="commentActionhand"
                                                                                [ngClass]="comment.disliked === true? 'active ': 'inactive'"
                                                                                (click)="comments_like_disliked(comment._id, 'disliked', session_id,comment.disliked)"><i
                                                                                    class="fas fa-thumbs-down mr-2"></i>
                                                                            </a>
                                                                            <b
                                                                                class="conunts black-text">{{comment.dislike_count}}</b>
                                                                        </span>
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </ng-template>
                                    </li>

                                    <li [ngbNavItem]="4">
                                        <a ngbNavLink (click)="viewQuestions()">Questions</a>
                                        <ng-template ngbNavContent>
                                            <div class="nWV-tabs-content-block nWV-tabs-Questions-content">

                                                <div class="row justify-content-center">
                                                    <div class="col-md-11">
                                                        <div class="input-with-icon input-with-icon-right mt-4 mb-4 ml-auto"
                                                            *ngIf="role == 'student'">
                                                            <input type="text" placeholder="Ask a Question"
                                                                class="form-control form-control-md" />
                                                            <button type="button" class="input-with-icon-btn search-btn"
                                                                (click)="askaQuestion(session_id, courseId)"><i
                                                                    class="fas fa-plus"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="" *ngIf="myQuestions?.length == 0">
                                                    <hr />
                                                </div>
                                                <div class="alert alert-info text-center"
                                                    *ngIf="myQuestions?.length == 0">
                                                    <p>Question are not available yet.</p>
                                                </div>

                                                <div class="nWV-accordionCustom" *ngIf="myQuestions?.length > 0">
                                                    <div class="col-md-12">

                                                        <div class="row" *ngIf="myQuestions.length > 0">
                                                            <div class="col-md-12">
                                                                <!-- Filter Block Start -->
                                                                <!-- <div class="filter-elem-block mb-4 border-top-0">
                                                                <div class="filter-elem-item filter-elem-left">
                                                                    <a id="fliter-btn" class="filter-elem-item-link"
                                                                        (click)="filterClickEvent()">
                                                                        <i class="fas fa-filter mr-2"></i>
                                                                        <h6 class="avenirBook">Filters</h6>
                                                                    </a>
                                                                </div>
                                                                <div class="filter-elem-item filter-elem-right">
                                                                    <form>
                                                                        <div class="sort-elenm">
                                                                        <label><small>Sort by:</small></label>
                                                                        <select class="form-control form-select">
                                                                            <option>Most Popular</option>
                                                                            <option>2</option>
                                                                            <option>3</option>
                                                                        </select>
                                                                        </div>
                                                                    </form>
                                                                    </div>
                                                            </div> -->

                                                                <!-- Filter Block End -->
                                                            </div>
                                                        </div>

                                                        <div class="questionBankLists mb-3"
                                                            [ngClass]="edit_note == true?'d-block':'d-none'">

                                                            <div class="questionBankItem bg-grey"
                                                                *ngFor="let myquestion of myQuestions;  let i = index;">
                                                                <div class="questionItemLeft">
                                                                    <h6 class="avenirBook mb-2"><a
                                                                            (click)="getQuestionsAnswer(myquestion.id)">{{myquestion.title}}</a>
                                                                    </h6>
                                                                    <div class="tags-badges">
                                                                        <span class="badge badge-green-lt"
                                                                            *ngFor="let tagss of myquestion.category; ">{{tagss}}</span>
                                                                    </div>

                                                                </div>
                                                                <div class="questionItemRight icons-group">

                                                                    <a class="questionItemLink ItemEditLink"
                                                                        (click)="EditQuestion(myquestion.id)"
                                                                        *ngIf="myquestion.count == 0 && userId == myquestion.user_id"
                                                                        ngbTooltip="Edit">
                                                                        <i class="fas fa-pencil-alt"></i></a>

                                                                    <a class="questionItemLink ItemDeleteLink"
                                                                        ngbTooltip="Delete"
                                                                        *ngIf="myquestion.approved_answer == 0 && userId == myquestion.user_id "
                                                                        (click)="deleteQuestionModel(deleteQues)">
                                                                        <i class="fas fa-trash-alt"></i></a>
                                                                </div>

                                                                <div class="questionItemRight icons-group"
                                                                    *ngIf="role != 'student'">

                                                                    <a class="questionItemLink ItemEditLink"
                                                                        (click)="EditQuestion(myquestion.id)"
                                                                        *ngIf="myquestion.count == 0 "
                                                                        ngbTooltip="Edit">
                                                                        <i class="fas fa-pencil-alt"></i></a>

                                                                    <a class="questionItemLink ItemDeleteLink"
                                                                        ngbTooltip="Delete"
                                                                        *ngIf="myquestion.approved_answer == 0"
                                                                        (click)="deleteQuestionModel(deleteQues)">
                                                                        <i class="fas fa-trash-alt"></i></a>
                                                                </div>

                                                                   <!-- new ui delete Question -->
                                                            <ng-template #deleteQues let-c="close" let-d="dismiss">
                                                                <div class="new_UIstyle">
                                                                    <div
                                                                        class="modal-header justify-content-between align-items-center">
                                                                        <h5
                                                                            class="avenirBook modal-title text-danger mb-0">
                                                                            Delete Question
                                                                        </h5>
                                                                        <button type="button" class="close ml-0"
                                                                            aria-label="Close"
                                                                            (click)="closeQuesPopup()">
                                                                            <span aria-hidden="true">&times;</span>
                                                                        </button>
                                                                    </div>
                                                                    <div class="modal-body">
                                                                        <p>Are you sure you want to delete Question
                                                                            <strong class="text-danger">?</strong></p>
                                                                    </div>

                                                                    <div class="modal-footer">
                                                                        <div class="text-left">
                                                                            <button type="submit"
                                                                                class="btn btn-primary btn-sm"
                                                                                (click)="closeQuesPopup()">NO</button>
                                                                        </div>
                                                                        <div class="text-right">
                                                                            <button type="submit"
                                                                                class="btn btn-primary btn-sm"
                                                                                (click)="deleteQuestion(myquestion.id)">YES</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-template>



                                                                <div class="questionItemFooter">
                                                                    <div class="questionItemFooterLeft">
                                                                        <!-- <p>{{myquestion.count}} Answers Given</p> -->
                                                                        <p>
                                                                            <span class="mr-3">
                                                                                {{myquestion.count}} Answers Given
                                                                            </span>
                                                                            <span class="avenirBlack color-grey">
                                                                                {{myquestion.Timestamp | date:'short'}}
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                    <div class="questionItemFooterRight">
                                                                        <p class="primary-text">
                                                                            <strong>{{myquestion.approved_answer}}
                                                                                Answers Approved</strong></p>
                                                                    </div>
                                                                </div>
                                                                <a (click)="getQuestionAnswer(myquestion.id)"
                                                                    class="questionItemLink ItemAnchorLink"><i
                                                                        class="fa fa-angle-right"></i></a>
                                                            </div>

                                                         


                                                        </div>

                                                        <div class="section anwser-view-wrap"
                                                            [ngClass]="QuestDetails == true?'d-block':'d-none'">
                                                            <div
                                                                class="title text-start d-flex align-items-center justify-content-between">
                                                                <h5 class="avenirBook primary-text mb-0">Question
                                                                    Resources</h5>

                                                                <div class="ansDetailActionItemBack">
                                                                    <button type="button" class="btn btn-primary"
                                                                        (click)="goback('edit_note')">Back</button>
                                                                </div>
                                                                <hr />
                                                            </div>

                                                            <div class="alert alert-info text-center"
                                                                *ngIf="question_video_key?.length == 0 && question_image_key?.length == 0 && question_doc_key?.length == 0">
                                                                <p>Resources are not available yet.</p>
                                                            </div>

                                                            <div class="question-answer-items question-resourceWrap">
                                                                <div class="answerVideoBlock">

                                                                    <div class="answerVideoListItem"
                                                                        *ngIf="question_video_key?.length > 0">
                                                                        <h6 class="primary-text avenirBlack">Videos</h6>
                                                                        <div class="row">
                                                                            <div class="col-lg-4 col-md-6 mb-3"
                                                                                *ngFor="let resourceVideo of question_video_key; let i = index;">
                                                                                <div class="answerVideoBox">
                                                                                    <div class="ratio ratio-16x9">
                                                                                        <video width="540" height="310"
                                                                                            controls>
                                                                                            <source
                                                                                                [src]="resourceVideo"
                                                                                                type="video/mp4">
                                                                                        </video>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="answerVideoListItem"
                                                                        *ngIf="question_image_key?.length > 0">
                                                                        <hr />
                                                                        <h6 class="primary-text avenirBlack">Images</h6>
                                                                        <div class="row ">
                                                                            <div class="col-lg-4 col-md-6  mb-3"
                                                                                *ngFor="let ansResourceImg of question_image_list; let i = index;">
                                                                                <div class="answerVideoBox">
                                                                                    <div class="ratio ratio-16x9">
                                                                                        <img
                                                                                            [src]="ansResourceImg.fileUrl" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div class="learning-course-items-wrap learning-course-resources-wrap"
                                                                    *ngIf="question_doc_key?.length > 0">
                                                                    <hr />
                                                                    <h6 class="primary-text avenirBlack">Files</h6>
                                                                    <div class="learning-course-item"
                                                                        *ngFor="let docRes of question_doc_path; let i = index;">

                                                                        <div class="learning-course-link">
                                                                            <div class="learning-course-elem-left">
                                                                                <p class="learning-course-link-title">
                                                                                    <span class="title-icon"><i
                                                                                            class="fas fa-folder"></i></span><strong>{{question_doc_key[i]
                                                                                        | splitTitle}}</strong>
                                                                                </p>
                                                                            </div>
                                                                            <div class="learning-course-elem-right">
                                                                                <ul class="list-icon-wrap">
                                                                                    <li>
                                                                                        <a class="learning-course-download"
                                                                                            [href]="docRes"
                                                                                            target="_blank"
                                                                                            [download]="docRes">
                                                                                            <i
                                                                                                class="fas fa-download"></i>
                                                                                        </a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr />

                                                            <div
                                                                class="title d-flex align-items-center justify-content-between">
                                                                <div class="title-left-cont text-start">
                                                                    <h5 class="avenirBook primary-text mb-0">Answer</h5>

                                                                    <p *ngIf="answersCounts == 0">There is
                                                                        {{answersCounts}} Answer</p>
                                                                    <p *ngIf="answersCounts == 1">There is
                                                                        {{answersCounts}} Answer</p>
                                                                    <p *ngIf="answersCounts > 1">There are
                                                                        {{answersCounts}} Answers</p>
                                                                </div>
                                                                <div class="btn-groups ansDetailAction">
                                                                    <div
                                                                        class="ansDetailActionItem d-flex align-items-center justify-content-end">
                                                                        <!-- <div class="likeDislike ansDetailActionItem" *ngIf="answersCounts > 0" >
                                                                            <ul class="likeDisList">
                                                                                <li [ngClass]="isLiked == true ? 'active': ''">
                                                                                    <a class="likeAction" href="javascript:void(0);" (click)="setLikeDislike('liked',answers._id)" >
                                                                                      <i class="fa fa-thumbs-up" aria-hidden="true"></i></a> 
                                                                                      <span class="likeDisCount ml-2">{{like_count}}</span></li>
                                                                                <li [ngClass]="isDisLiked == true ? 'active':' '">
                                                                                    <a  class="likeAction" href="javascript:void(0);" (click)="setLikeDislike('disliked',answers._id)">
                                                                                        <i class="fa fa-thumbs-down" aria-hidden="true"></i></a> 
                                                                                        <span class="likeDisCount ml-2">{{dislike_count}}</span></li>
                                                                            </ul>
                                                                        </div> -->

                                                                        <!-- <button class="btn btn-primary ansDetailActionItem"   *ngIf="approval_status != 'approved' && userId != ans_user_id && question_status == 'open'"
                                                                     
                                                                        >Approve Answer</button> -->
                                                                        <!-- <span class="dark-green-text ansDetailActionItem" 
                                                                        *ngIf="approval_status == 'approved'"><i class="fas fa-check-double"></i><b> Answer Approved</b></span> -->

                                                                    </div>

                                                                    <button type="button"
                                                                        class="btn btn-primary ansDetailActionItem"
                                                                        *ngIf=" userId == question_user_id && question_status == 'open'  && role == 'student'"
                                                                        (click)="closeQuestion()">Close Question
                                                                        <i class="fas fa-times ml-2"></i>
                                                                    </button>
                                                                    <button type="button" class="btn btn-primary"
                                                                        (click)="giveYourAnswer(ques_id)"
                                                                        *ngIf="userId != question_user_id  && question_status != 'closed'">Write
                                                                        Your Answer</button>
                                                                </div>
                                                            </div>
                                                            <hr class="mt-3" />

                                                            <div class="questionBankLists mb-3">
                                                                <div class="text-center"
                                                                    [ngClass]="{submitting : loader}">
                                                                    <div class="spinner-border text-warning formLoader"
                                                                        role="status" *ngIf="loader">
                                                                        <span class="sr-only">Loading...</span>
                                                                    </div>
                                                                </div>
                                                                <div class="questionBankItem bg-grey"
                                                                    *ngFor="let ans of answers; let i = index;">
                                                                    <div class="questionItemLeft">
                                                                        <div class="mb-2 questionItemLeftEllipse"
                                                                            *ngIf="ans.answer_html != ''">
                                                                            <a [innerHTML]="ans.answer_html | slice:0:150"
                                                                                (click)="getAnswerDetailRoute(ans._id)"></a>...
                                                                        </div>
                                                                        <h6 class="avenirBook mb-2"
                                                                            *ngIf="ans.answer_html == ''">
                                                                            <a (click)="getAnswerDetailRoute(ans._id)"><b>This
                                                                                    is a recorded answer</b></a>
                                                                        </h6>
                                                                    </div>
                                                                    <div class="questionItemRight icons-group">
                                                                        <a class="questionItemLink ItemEditLink"
                                                                            (click)="editAnswer(ans._id)"
                                                                            *ngIf="ans.user_id == userId && ans.approval_status != 'approved'"
                                                                            ngbTooltip="Edit">
                                                                            <i class="fas fa-pencil-alt"></i></a>

                                                                        <a class="questionItemLink ItemDeleteLink"
                                                                            *ngIf="ans.user_id == userId && ans.approval_status != 'approved'"
                                                                            (click)="deleteAnswerModel(deleteAns)"
                                                                            ngbTooltip="Delete">
                                                                            <i class="fas fa-trash-alt"></i></a>
                                                                    </div>

                                                                           <!-- new ui delete Answer -->
                                                            <ng-template #deleteAns let-c="close" let-d="dismiss">
                                                                <div class="new_UIstyle">
                                                                    <div
                                                                        class="modal-header justify-content-between align-items-center">
                                                                        <h5
                                                                            class="avenirBook modal-title text-danger mb-0">
                                                                            Delete Answer
                                                                        </h5>
                                                                        <button type="button" class="close ml-0"
                                                                            aria-label="Close"
                                                                            (click)="closeQuesPopup()">
                                                                            <span aria-hidden="true">&times;</span>
                                                                        </button>
                                                                    </div>
                                                                    <div class="modal-body">
                                                                        <p>Are you sure you want to delete Answer
                                                                            <strong class="text-danger">?</strong></p>
                                                                    </div>

                                                                    <div class="modal-footer">
                                                                        <div class="text-left">
                                                                            <button type="submit"
                                                                                class="btn btn-primary btn-sm"
                                                                                (click)="closeQuesPopup()">NO</button>
                                                                        </div>
                                                                        <div class="text-right">
                                                                            <button type="submit"
                                                                                class="btn btn-primary btn-sm"
                                                                                (click)="deleteToAnswer(ans._id)">YES</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-template>

                                                                    <div class="questionItemFooter">
                                                                        <div class="questionItemFooterLeft">
                                                                            <p class="primary-text list-space">
                                                                                <strong>{{ans.created_at |
                                                                                    date:'short'}}</strong></p>
                                                                        </div>
                                                                        <div class="questionItemFooterRight">
                                                                            <p class="primary-text"
                                                                                *ngIf="ans.approval_status == 'approved'">
                                                                                <i
                                                                                    class="fas fa-check-double"></i><strong>Answer
                                                                                    Approved</strong></p>
                                                                        </div>
                                                                    </div>
                                                                    <a class="questionItemLink ItemAnchorLink"
                                                                        (click)="getAnswerDetails(ans._id)"><i
                                                                            class="fa fa-angle-right"></i></a>
                                                                </div>

                                                                <div class="alert alert-info text-center"
                                                                    *ngIf="answers?.length == 0">
                                                                    <p>Answer is not available yet</p>
                                                                </div>

                                                            </div>

                                                        </div>



                                                        <div class="answerDetailWrap mb-3"
                                                            [ngClass]="AnsDetails == true?'d-block':'d-none'">


                                                            <div
                                                                class="title text-start d-flex align-items-center justify-content-between">
                                                                <h5 class="avenirBook primary-text mb-0">Question
                                                                    Resources</h5>

                                                                <div class="ansDetailActionItemBack">
                                                                    <button type="button" class="btn btn-primary"
                                                                        (click)="goback('QuestDetails')">Back</button>
                                                                </div>
                                                                <hr />
                                                            </div>


                                                            <div class="question-answer-items question-resourceWrap">
                                                                <div class="answerVideoBlock">

                                                                    <div class="answerVideoListItem"
                                                                        *ngIf="question_video_key?.length > 0">
                                                                        <h6 class="primary-text avenirBlack">Videos</h6>
                                                                        <div class="row">
                                                                            <div class="col-lg-4 col-md-6 mb-3"
                                                                                *ngFor="let resourceVideo of question_video_key; let i = index;">
                                                                                <div class="answerVideoBox">
                                                                                    <div class="ratio ratio-16x9">
                                                                                        <video width="540" height="310"
                                                                                            controls>
                                                                                            <source
                                                                                                [src]="resourceVideo"
                                                                                                type="video/mp4">
                                                                                        </video>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="answerVideoListItem"
                                                                        *ngIf="question_image_key?.length > 0">
                                                                        <hr />
                                                                        <h6 class="primary-text avenirBlack">Images</h6>
                                                                        <div class="row ">
                                                                            <div class="col-lg-4 col-md-6  mb-3"
                                                                                *ngFor="let ansResourceImg of question_image_list; let i = index;">
                                                                                <div class="answerVideoBox">
                                                                                    <div class="ratio ratio-16x9">
                                                                                        <img
                                                                                            [src]="ansResourceImg.fileUrl" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div class="learning-course-items-wrap learning-course-resources-wrap"
                                                                    *ngIf="question_doc_key?.length > 0">
                                                                    <hr />
                                                                    <h6 class="primary-text avenirBlack">Files</h6>
                                                                    <div class="learning-course-item"
                                                                        *ngFor="let docRes of question_doc_path; let i = index;">
                                                                        <div class="learning-course-link">
                                                                            <div class="learning-course-elem-left">
                                                                                <p class="learning-course-link-title">
                                                                                    <span class="title-icon"><i
                                                                                            class="fas fa-folder"></i></span><strong>{{question_doc_key[i]
                                                                                        | splitTitle}}</strong>
                                                                                </p>
                                                                            </div>
                                                                            <div class="learning-course-elem-right">
                                                                                <ul class="list-icon-wrap">
                                                                                    <li>
                                                                                        <a class="learning-course-download"
                                                                                            [href]="docRes"
                                                                                            target="_blank"
                                                                                            [download]="docRes">
                                                                                            <i
                                                                                                class="fas fa-download"></i>
                                                                                        </a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="alert alert-info text-center"
                                                                *ngIf="question_image_key?.length == 0 && question_doc_key?.length == 0 && question_video_key?.length == 0">
                                                                <p>Resources are not available yet.</p>

                                                            </div>

                                                            <hr class="mt-3">
                                                            <div
                                                                class="title d-flex align-items-center justify-content-between">
                                                                <div class="title-left-cont text-start">
                                                                    <h5 class="avenirBook primary-text mb-0">Answer
                                                                        <span clas="ansDetailClass"> Detail</span>
                                                                    </h5>

                                                                </div>
                                                                <div class="btn-groups ansDetailAction">
                                                                    <div
                                                                        class="ansDetailActionItem d-flex align-items-center justify-content-end">
                                                                        <div class="likeDislike ansDetailActionItem"
                                                                            *ngIf="answersCounts > 0"
                                                                            [ngClass]="userId == ansDetailUserID ? 'noTouched' : ''">
                                                                            <ul class="likeDisList">
                                                                                <li
                                                                                    [ngClass]="isLiked == true ? 'active': ''">
                                                                                    <a class="likeAction"
                                                                                        href="javascript:void(0);"
                                                                                        (click)="setLikeDislike('liked')">
                                                                                        <i class="fa fa-thumbs-up"
                                                                                            aria-hidden="true"></i></a>
                                                                                    <span
                                                                                        class="likeDisCount ml-2">{{like_count}}</span>
                                                                                </li>
                                                                                <li
                                                                                    [ngClass]="isDisLiked == true ? 'active':' '">
                                                                                    <a class="likeAction"
                                                                                        href="javascript:void(0);"
                                                                                        (click)="setLikeDislike('disliked')">
                                                                                        <i class="fa fa-thumbs-down"
                                                                                            aria-hidden="true"></i></a>
                                                                                    <span
                                                                                        class="likeDisCount ml-2">{{dislike_count}}</span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>

                                                                        <button
                                                                            class="btn btn-primary ansDetailActionItem"
                                                                            *ngIf="userId == question_user_id && approval_status != 'approved'"
                                                                            (click)="approveAnswer()">Approve
                                                                            Answer</button>
                                                                        <span
                                                                            class="dark-green-text ansDetailActionItem"
                                                                            *ngIf="approval_status == 'approved'"><i
                                                                                class="fas fa-check-double"></i><b>
                                                                                Answer Approved</b></span>

                                                                    </div>
                                                                    <button type="button"
                                                                        class="btn btn-primary ansDetailActionItem"
                                                                        *ngIf="question_status == 'open' &&  role == 'student' "
                                                                        (click)="closeQuestion()">Close Question
                                                                        <i class="fas fa-times ml-2"></i>
                                                                    </button>
                                                                    <!-- <button type="button" class="btn btn-primary" 
                                                                    *ngIf="userId != question_user_id && question_status != 'closed'"
                                                                    (click)="giveYourAnswer(question__id)"
                                                                    >Write Your Answer</button> -->
                                                                </div>
                                                            </div>
                                                            <hr class="mt-3" />
                                                            <div class="answerContentBlock mb-4"
                                                                [innerHTML]="answerHtml"></div>
                                                            <hr class="mt-3" />
                                                            <div class="question-answer-items answer-resourceWrap">
                                                                <div class="answerVideoBlock">
                                                                    <div class="answerVideoListItem"
                                                                        *ngIf="ansResourceVideosAns?.length > 0">
                                                                        <hr />
                                                                        <h6 class="primary-text avenirBlack">Videos</h6>
                                                                        <div class="row">
                                                                            <div class="col-lg-4 col-md-6 mb-3"
                                                                                *ngFor="let ansResourceVideo of ansResourceVideosAns; let i = index;">
                                                                                <div class="answerVideoBox">
                                                                                    <div class="ratio ratio-16x9">
                                                                                        <video width="540" height="310"
                                                                                            controls>
                                                                                            <source
                                                                                                [src]="ansResourceVideo"
                                                                                                type="video/mp4">
                                                                                        </video>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="answerVideoListItem"
                                                                        *ngIf="resourceImagesAns?.length > 0">
                                                                        <hr />
                                                                        <h6 class="primary-text avenirBlack">Images</h6>
                                                                        <div class="row ">
                                                                            <div class="col-lg-4 col-md-6  mb-3"
                                                                                *ngFor="let ansResourceImg of resourceImagesAns; let i = index;">
                                                                                <div class="answerVideoBox">
                                                                                    <div class="ratio ratio-16x9">
                                                                                        <img [src]="ansResourceImg"
                                                                                            [alt]="ansResourceImg" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div class="learning-course-items-wrap learning-course-resources-wrap"
                                                                    *ngIf="resourceDocsKeyAns?.length > 0">
                                                                    <hr />
                                                                    <h6 class="primary-text avenirBlack">Files</h6>
                                                                    <div class="learning-course-item"
                                                                        *ngFor="let ansDocRes of resourceDocsAns; let i = index;">
                                                                        <div class="learning-course-link">
                                                                            <div class="learning-course-elem-left">
                                                                                <p class="learning-course-link-title">
                                                                                    <span class="title-icon"><i
                                                                                            class="fas fa-folder"></i></span><strong>{{resourceDocsKeyAns[i]
                                                                                        | splitTitle}}</strong>
                                                                                </p>
                                                                            </div>
                                                                            <div class="learning-course-elem-right">
                                                                                <ul class="list-icon-wrap">
                                                                                    <li>
                                                                                        <a class="learning-course-download"
                                                                                            [href]="resourceDocsAns[i]"
                                                                                            target="_blank"
                                                                                            [download]="resourceDocsAns[i]">
                                                                                            <i
                                                                                                class="fas fa-download"></i>
                                                                                        </a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                </div>
                                                            </div>

                                                            <div class="comment_Detail_wrap">
                                                                <hr />
                                                                <div
                                                                    class="title d-flex align-items-center justify-content-between">
                                                                    <div class="title-left-cont text-start">
                                                                        <h5 class="avenirBook primary-text mb-0">Comment
                                                                        </h5>
                                                                    </div>
                                                                    <!-- comment sorting -->
                                                                    <div class="btn-groups ansDetailAction"
                                                                        *ngIf="FeedbacksList.length > 0">
                                                                        <button type="button" class="btn btn-sm"
                                                                            [ngClass]="topFilter ? 'btn-primary':'btn-grey'"
                                                                            (click)="sortComment('top')">Top</button>
                                                                        <button type="button" class="btn btn-sm "
                                                                            [ngClass]="newFilter? 'btn-primary':'btn-grey'"
                                                                            (click)="sortComment('Newest')">Newest</button>
                                                                    </div>
                                                                </div>
                                                                <hr class="mt-3 mb-5" />
                                                                <div class="commentElemBlock">
                                                                    <div class="writeCommentWrap border-0">
                                                                        <div class="commentleftElem">
                                                                            <figure class="commentDp mb-5"
                                                                                *ngIf="avatarURL">
                                                                                <img [src]="avatarURL" alt="avtar" />
                                                                            </figure>
                                                                            <figure
                                                                                class="commentDp mb-5 commentDp-title"
                                                                                *ngIf="!avatarURL">
                                                                                {{first_name[0] | titlecase}}
                                                                            </figure>
                                                                        </div>
                                                                        <div class="commentRightElem relative">
                                                                            <div class="text-center loadingScreen"
                                                                                [ngClass]="{submitting : loader}"
                                                                                *ngIf="loader">
                                                                                <div class="spinner-border text-warning formLoader"
                                                                                    role="status" *ngIf="loader">
                                                                                    <span
                                                                                        class="sr-only">Loading...</span>
                                                                                </div>
                                                                            </div>
                                                                            <form id="commentVideo" #feedBack="ngForm"
                                                                                (ngSubmit)="sendAnswerFeedback(feedBack,answer_id)">


                                                                                <textarea type="text"
                                                                                    class="form-control form-control-textarea w-100"
                                                                                    name="feedBack" ngModel
                                                                                    placeholder="Add a comment... "></textarea>


                                                                                <div
                                                                                    class="commentFooter btn-groups justify-content-end mt-4">
                                                                                    <!-- <button type="button" class="btn btn-sm btn-primary mr-3"
                                                                                    (click)="resetFeedBack(feedBack)">Cancel</button> -->
                                                                                    <button type="submit"
                                                                                        class="btn btn-sm btn-primary">Post</button>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>


                                                                    <div class="commentListElemWrap">
                                                                        <!-- Comment Row start -->
                                                                        <div class="commentElemrow"
                                                                            *ngFor="let comment of FeedbacksList  | paginate: { itemsPerPage: 10, currentPage: pageComment, totalItems:totalcomments }; let i = index;">
                                                                            <div class="commentleftElem commentUserDp">
                                                                                <figure class="commentDp commentDp-sm"
                                                                                    *ngIf="comment.profile_pic != ''">
                                                                                    <img [src]="comment.profile_pic" />
                                                                                </figure>
                                                                                <figure
                                                                                    class="commentDp commentDp-sm commentDp-title"
                                                                                    *ngIf="comment.profile_pic == ''">
                                                                                    {{comment.name | slice:0:1}}
                                                                                </figure>
                                                                            </div>
                                                                            <div
                                                                                class="commentRightElem commentUserDetail">
                                                                                <p>
                                                                                    <small
                                                                                        class="d-flex align-item-center commentUserhead">
                                                                                        <strong
                                                                                            class="commentName avenirBlack primary-text">{{comment.name
                                                                                            |
                                                                                            titlecase}}</strong>
                                                                                        <span class="commentTime ">
                                                                                            &nbsp; &#x2022; &nbsp;
                                                                                            {{comment.Timestamp |
                                                                                            dateTimeAgo}}</span>
                                                                                    </small>
                                                                                    <span class="textComment"
                                                                                        [innerHTML]="comment.Feedback"></span>


                                                                                    <span
                                                                                        class="commentUserFoot d-flex">
                                                                                        <span
                                                                                            class="mr-3 me-md-5 commentActionElem likeComment">
                                                                                            <a class="commentActionhand"
                                                                                                [ngClass]="comment.Liked == true ? 'active': 'inActive'"
                                                                                                (click)="ansCommentLikeDis(comment._id, 'liked',comment.Liked)"><i
                                                                                                    class="fas fa-thumbs-up mr-2"></i>
                                                                                            </a>
                                                                                            <b
                                                                                                class="conunts color-grey">{{comment.like_count}}</b>

                                                                                        </span>
                                                                                        <span
                                                                                            class="commentActionElem disLikeComment">
                                                                                            <a class="commentActionhand mt-1"
                                                                                                [ngClass]="comment.Disliked == true ? 'active': 'inActive'"
                                                                                                (click)="ansCommentLikeDis(comment._id, 'disliked',comment.Disliked)"><i
                                                                                                    class="fas fa-thumbs-down mr-2"></i>
                                                                                            </a>
                                                                                            <b
                                                                                                class="conunts color-grey">{{comment.dislike_count}}</b>
                                                                                        </span>
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <pagination-controls
                                                                            class="text-center my-pagination"
                                                                            *ngIf="FeedbacksList?.length > 0"
                                                                            previousLabel="<" nextLabel=">" maxSize="10"
                                                                            (pageChange)="pageComment = $event"></pagination-controls>

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>



                                                    </div>




                                                </div>
                                            </div>

                                        </ng-template>


                                    </li>

                                    <li [ngbNavItem]="5">
                                        <a ngbNavLink (click)="getCommentsInfo(session_id, courseId)">Assessement</a>
                                        <ng-template ngbNavContent>
                                            <div class="sc-tabs-content-block sc-tabs-Assessement-content">
                                                <div class="learning-course-items-wrap learning-course-assessment-wrap">
                                                 
                                                    
                                                <div class="" *ngIf="getRolesPremission() && public_right">
                                                    <div class="tabsViewSession">
                                                        <div class="btn tabLink" (click)="selectTabView('approvedAssessment')" 
                                                        [class.active]="selectedActiveTab === 'approvedAssessment'">
                                                            Approved Assessments 
                                                        </div>
                                                        <div class="btn tabLink" (click)="selectTabView('unApprovedAssessment')" 
                                                        [class.active]="selectedActiveTab === 'unApprovedAssessment'">
                                                            Unapproved AI-generated Assessments
                                                        </div>
                                                        </div>
                                                        
                                                        <div class="tabViewContent">

                                                        <div *ngIf="selectedActiveTab === 'approvedAssessment'" class="tabViewContentBox" [class.active]="selectedActiveTab === 'approvedAssessment'">
                                                            <app-assessment-tiles [assessment_data]="Topic_assessments" [sessions]="sessions" [isTA]="isTA"
                                                            [topicId]="topicId" [session_id]="session_id"
                                                            [session_status]="session_status" (emitAssViewSubmission)="recivedAssViewSub($event)"
                                                            (emitAssementGraph)="recivedAssessmentGraph($event)"></app-assessment-tiles>
                                                            <!-- no data found-->
                                                            <app-lengthnotfound [contentData]="nodatafound" [conditionValue]="Topic_assessments.length === 0"></app-lengthnotfound>
                                                        </div>



                                                        <div *ngIf="selectedActiveTab === 'unApprovedAssessment'" class="tabViewContentBox" 
                                                        [class.active]="selectedActiveTab === 'unApprovedAssessment'"> 
                                                        <app-unapproved-assessment [unapproved_assessment_data]="unapproved_ai_assesment_data" 
                                                        [closeAssementEvent]="closeAssementmodel.asObservable()"
                                                        (delete_Assessment_emit)="deleteAssessmentEvent($event)"
                                                        (aiAssessmentDetailEmit)="getAiAssessmentDetail($event)"></app-unapproved-assessment>
                                                            <!-- no data found for teacher/Admin and unpublished assessment -->
                                                        <app-lengthnotfound [contentData]="nodatafound" [conditionValue]="unapproved_ai_assesment_data.length === 0"></app-lengthnotfound>
                                                        </div>
                                                        </div>
                                                </div>


                                                <div *ngIf="((getPremissionAsessmentview() && (isTA === 'true' || isTA === 'false')) || (getPremissionAsessmentview())) && !public_right">
                                                    <!-- no data found for student -->
                                                    <app-lengthnotfound [contentData]="nodatafound" [conditionValue]="Topic_assessments.length == 0"></app-lengthnotfound>
                                                    <app-assessment-tiles [assessment_data]="Topic_assessments" [sessions]="sessions" [isTA]="isTA"
                                                    [topicId]="topicId" [session_id]="session_id"
                                                    [session_status]="session_status" (emitAssessmentData)="recivedAssessmentData($event)"
                                                    (emitAssessmentView)="recivedAssessmentView($event)" (emitAssViewSubmission)="recivedAssViewSub($event)"
                                                    (emitAssementGraph)="recivedAssessmentGraph($event)"></app-assessment-tiles>
                                                
                                                </div>

                                                    

                                                </div>
                                            </div>
                                        </ng-template>
                                    </li>

                                    <li [ngbNavItem]="6">
                                        <a ngbNavLink (click)="getTranscription()">Transcription</a>
                                        <ng-template ngbNavContent>
                                            <div class="sc-tabs-content-block sc-tabs-transption-content">
                                                <div class="alert alert-info text-center"
                                                    *ngIf="transcription_details.length == 0">
                                                    <p>Transcription is not available yet.</p>
                                                </div>
                                                <div class="nWV-accordionCustom">
                                                    <ngb-accordion #transption="ngbAccordion" [closeOthers]="true"
                                                        activeIds="active" class="accordionCustom">
                                                        <ngb-panel id="active-{{indOftrans}}"
                                                            *ngFor="let item of transcription_details; let indOftrans = index"
                                                            [ngClass]="(issueReport && indOfEdit == indOftrans) ? 'editing' : 'disabled' ">
                                                            <ng-template ngbPanelHeader>
                                                                <div
                                                                    class="d-flex panelHeaderButton panelHeaderActionBtn">

                                                                    <div class="panelHeaderElem panelHeaderLeft">
                                                                        <p>
                                                                            <span class="d-block mb-2 topic-heading"><strong>{{item.Topic}}</strong></span>
                                                                            <strong>Time frame:</strong>
                                                                            {{item.start_time}} -
                                                                            {{item.end_time}} <button
                                                                                (click)="play(item)"
                                                                                class="ms-3 transprantBtn videoPlay"><i
                                                                                    class="fas fa-play-circle"></i>
                                                                            </button></p>
                                                                    </div>

                                                                    <div class="panelHeaderElem panelHeaderEnd"
                                                                        *ngIf="enableReportIssue && !item?.pendings && sessions && sessions.publish_right">
                                                                        <p class="dark-green-text">No issues reported
                                                                        </p>
                                                                    </div>
                                                                    <div class="panelHeaderElem panelHeaderRight">
                                                                        <div class="panelHeaderActionBlock"
                                                                            *ngIf="enableReportIssue && item?.pendings && sessions && sessions.publish_right">
                                                                            <ul>

                                                                                <li ngbDropdown class="dropdown">
                                                                                    <a class="issue-report-toggle"
                                                                                        ngbDropdownToggle><i
                                                                                            class="far fa-flag me-1"></i>
                                                                                        1
                                                                                        issue
                                                                                        reported</a>
                                                                                    <div ngbDropdownMenu
                                                                                        class="dropdown-menu-end reported-issue-menu">
                                                                                        <form class="typo-16">
                                                                                            <h6
                                                                                                class="avenirBlack primary-text">
                                                                                                Issues
                                                                                                Reported</h6>

                                                                                            <div
                                                                                                class="issuse-report-list">
                                                                                                <div
                                                                                                    class="mb-3 issuse-report-item">
                                                                                                    <label
                                                                                                        class="mb-1">Time
                                                                                                        frame:
                                                                                                        <strong
                                                                                                            class="primary-text">{{item.start_time}}
                                                                                                            -
                                                                                                            {{item.end_time}}</strong></label>
                                                                                                    <textarea
                                                                                                        class="form-control form-control-textarea"
                                                                                                        disabled
                                                                                                        value=" {{item?.pendings.suggestion}}"></textarea>
                                                                                                </div>

                                                                                                <div
                                                                                                    class="issuse-report-item btn-groups justify-content-end">
                                                                                                    <button
                                                                                                        class="btn btn-sm btn-grey-dark me-3"
                                                                                                        (click)="report_issue_trans(item, 'decline')">Decline</button>
                                                                                                    <button
                                                                                                        class="btn btn-sm btn-primary"
                                                                                                        (click)="report_issue_trans(item, 'approve')">Approve</button>
                                                                                                </div>

                                                                                            </div>
                                                                                        </form>
                                                                                    </div>
                                                                                </li>

                                                                            </ul>
                                                                        </div>
                                                                        <button ngbPanelToggle class="ngbPanelToggleBtn"
                                                                            id="ngbPanelToggle1">
                                                                            <span class="acc-headerAngel"><i
                                                                                    class="fas fa-angle-down"></i></span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                            <ng-template ngbPanelContent class="relative">
                                                                <div class="submit-report-box" *ngIf="enableReportIssue && !item?.pendings">
                                                                    <div ngbDropdown class="dropdown">
                                                                        <a class="issue-report-toggle"
                                                                            ngbDropdownToggle><img
                                                                                src="../../../assets/images/apps-icon/comment-plus.png"
                                                                                alt="comment-plus.png" /></a>
                                                                        <div ngbDropdownMenu
                                                                            class="dropdown-menu-end reported-submit-menu"
                                                                            *ngIf="reportIssue">
                                                                            <form class="typo-16">
                                                                                <h6 class="avenirBlack primary-text">
                                                                                    Report an
                                                                                    issue
                                                                                </h6>

                                                                                <div class="issuse-report-list">
                                                                                    <div
                                                                                        class="mb-3 issuse-report-item">
                                                                                        <label class="mb-1">Time frame:
                                                                                            <strong
                                                                                                class="primary-text">{{item.start_time}}
                                                                                                -
                                                                                                {{item.end_time}}</strong></label>
                                                                                        <textarea
                                                                                            class="form-control form-control-textarea"
                                                                                            [value]="item.transcript"
                                                                                            (change)="save_self($event)"></textarea>
                                                                                    </div>
                                                                                    <div
                                                                                        class="issuse-report-item btn-groups justify-content-end">
                                                                                        <button
                                                                                            class="btn btn-sm btn-grey-dark me-3"
                                                                                            (click)="cancelTrans()">Cancel</button>
                                                                                        <button
                                                                                            class="btn btn-sm btn-primary"
                                                                                            (click)="report_issue_trans(item, 'self')">Submit</button>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="panelContentWrap relative">

                                                                    <p>{{item.transcript}}
                                                                    </p>

                                                                </div>

                                                            </ng-template>

                                                        </ngb-panel>

                                                    </ngb-accordion>
                                                </div>
                                            </div>
                                        </ng-template>

                                    </li>
                                </ul>
                            </div>

                            <div class="video-notes-tabs-right justify-content-between form">
                                <!-- <div class="" *ngIf="go_live_session(sessions,sessionkey) && role != 'student'">
                                    <label class="switch switch-green" for="customSwitcheLive">
                                        <input type="checkbox" class="switch-input" id="customSwitcheLive"
                                            [checked]="session_status == 'active'" (change)="golive($event)">
                                        <span class="switch-label" data-on="Go Live" data-off="Stop Live"></span>
                                        <span class="switch-handle"></span>
                                    </label>
                                </div> -->
                                <div class="video-notes-tabs-right justify-content-end">
                                    <button class="btn btn-primary btn-sm"
                                        *ngIf="sessionStatus != ''&& sessionkey == 'live_session' && role != 'student'"
                                        (click)="automarkAttendance()">Mark
                                        Attendance</button>
                                </div>
                            </div>
                        </div>
                        <div [ngbNavOutlet]="videoNotesTabs" class="mt-4"></div>
                    </div>
                </div>
            </div>

        </section>
    </div>

</div>