import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-assessment-tiles',
  templateUrl: './assessment-tiles.component.html',
  styleUrls: ['./assessment-tiles.component.scss']
})
export class AssessmentTilesComponent implements OnInit {
  role:string = null;
  @Input() assessment_data:any =  [];
  @Input() sessions:any = {};
  @Input() topicId:string = '';
  @Input() session_id:string = '';
  @Input() session_status:any = '';
  @Input() isTA:string = 'false';
  @Output()  emitAssessmentData = new EventEmitter<object>();
  @Output()  emitAssessmentView = new EventEmitter<object>();
  @Output()  emitAssViewSubmission = new EventEmitter<object>();
  @Output()  emitAssementGraph = new EventEmitter<object>();
  objSession:any;
  constructor() { }

  ngOnInit() {
    this.role = localStorage.getItem('role');
    this.objSession = Object.keys(this.sessions).length
  }

  is_answer_assessment(assessment) {
    if (assessment.one_time_assessment) {
      return assessment.user_submission
    } else {
      return assessment.view_feedback
    }
  }

  openAssessmentForSession(session, assessment, time, index?: number) {
    let emitassessmntObject = {
      'session':session,
      'assessment':assessment,
      'time':time,
      'index':index
    }
    this.emitAssessmentData.emit(emitassessmntObject);
  }

  viewUserSubmission(assesments_info, topicId, sessionId){
    let emitassessmntViewObject = {
      'assesments_info':assesments_info,
      'topicId':topicId,
      'sessionId':sessionId
    }
    this.emitAssessmentView.emit(emitassessmntViewObject);
  }

  viewSubmission(submission, assessment, topic_id?: string) {
    let emitAssSubmsstionViewObject = {
      'submission':submission,
      'assessment':assessment,
      'topic_id':topic_id
    }
    this.emitAssViewSubmission.emit(emitAssSubmsstionViewObject);
  }
  getGraphAssessment(assessmentdata){
    let emitAssessmentGraphPbj = {
      'assessmentdata':assessmentdata
    }
    this.emitAssementGraph.emit(emitAssessmentGraphPbj);
  }

}


