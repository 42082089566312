import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-lengthnotfound',
  templateUrl: './lengthnotfound.component.html',
  styleUrls: ['./lengthnotfound.component.scss']
})
export class LengthnotfoundComponent implements OnInit {

  @Input() contentData:string;
  @Input() conditionValue:boolean = false;
  constructor() { }

  ngOnInit() {
  }

}
