import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PermissionService } from '../../services/permission.service';
import { finalize } from 'rxjs/operators';
import { CourseService } from '../../services/course.service';
import { NotificationService } from '../../services/notification.service';
import { environment } from '../../../environments/environment';
import { FormGroup, FormBuilder, Validators, NgForm, FormArray } from '@angular/forms';
import { NgbDateStruct, NgbModal, NgbModalRef, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import * as fileSaver from 'file-saver';
import { ShowCourseView } from 'src/app/model/course.model';
import { CourseWorkUtilityService } from '../../services/course-work-utility.service';

@Component({
  selector: 'app-course-work',
  templateUrl: './course-work.component.html',
  styleUrls: ['./course-work.component.scss']
})
export class CourseWorkComponent implements OnInit {

  //FOR SUBMISSION
  course_work_view: any;
  showCourseData: any;
  submission_button = 'Publish'
  courseworks_submission: any[] = [];
  isSubscribed = false;
  listdataarray = [];
  teacher_view_submission: any[] = [];
  getSubscribeStudentList = [];
  role: string = '';
  teamName: string = '';
  submission_publish = 'unpublished';

  //FOR STUDENT COURSE WORK
  loader: boolean = false;
  courseName: string = '';
  courseId: string = '';
  courseWorkId: string = '';
  topicId: string = '';
  courseworks: any = {};
  courseData: any = {};
  courseTitle: string = '';
  isTopicCoursework: boolean = false;

  //FOR TEAM
  groupProcessing: boolean = false;
  courseworkTeaminfo: any = [];
  userDefaultPic: string = environment.imageBasePath + environment.userDefaultProfilePic;
  createGroupModal: NgbModalRef;
  addTeamByStudnet: FormGroup;
  subscribeStudentList = [];
  addedMembers: any = [];
  resetTeam: boolean = false;
  groupActionName = 'Create';
  editCourseworkTeam: any = {};
  uploadedImg: string = '';
  resourcePath: string = '';
  loggedInUserId: string = '';
  defaultSubscriber: any = {};
  showCreateGroup: boolean = false;

  //CHILD COMPONENT DATA
  viewSubmissionData: any[] = [];
  reviewSavedStatusData: any = {};

  //FOR ACCEPT REJECT INVITE
  isPendingInvite: boolean = false;
  inviteProcess: boolean = false;

  activeIdNav:any= 1;
  courseNamePram:string = null;
  notificationParam:any = false;
  constructor(
    private permissionService: PermissionService,
    private courseService: CourseService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private modalReview: NgbModal,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private cwUtilityService: CourseWorkUtilityService) { }

  ngOnInit() {
    this.activeIdNav = Number(this.route.snapshot.queryParamMap.get('tab_no'));
    this.courseNamePram = this.route.snapshot.queryParamMap.get('course');
    const role = localStorage.getItem('role');
    this.role = role;
    this.loggedInUserId = localStorage.getItem('user_id');

    //CHECK PERMISSION
    if (!this.permissionService.isAllowedPageView(['student'], role)) {
      this.router.navigate(['/']);
      return false;
    }

    this.route.queryParams.subscribe(params => {
      this.courseWorkId = params.courseWork;
      this.courseId = params.course_id;
      this.courseName = params.courseWork;
      this.topicId = params.topic_id;
      if((params.notification === undefined) || (params.notification === null)){
        this.notificationParam = false
      } else{
        this.notificationParam = Boolean(params.notification)

      }
      if (this.topicId) {
        this.isTopicCoursework = true;
      }
      this.initializePage();
    });
  }

  initializePage() {
    this.getCourseWorkDetails();
  }

  getCourseWorkDetails() {
    this.loader = true;
    let current_time = new Date();

    this.courseService.getSingleCoursework(this.courseId, this.courseName).subscribe(res => {

        this.courseData = res.course_info;
        this.courseTitle = this.courseData.subject;
        this.isSubscribed = this.courseData.subscription_status;

      //CHECK SUBSCRIPTION
      if (!this.permissionService.isCourseSubscribed(this.courseData.subscription_status)) {
        this.router.navigate(['/']);
        return false;
      }

      //COURSE INFO DATA
      this.showCourseData = res;

      if (this.isTopicCoursework == false) {
        this.loader = false;
        this.prepareCourseworkInfo(res.course_work_info, 'course');
      }
      else {
        // INITIALIZE TOPIC COURSEWORK
        this.getTopicCourseWork();
      }
    }, err => {
      this.loader = false;
      this.handleError(err);
    });
  }

  getTopicCourseWork() {
    this.loader = true;
    this.courseService.get_topicSession(this.courseId, this.topicId).subscribe(res => {
      this.prepareCourseworkInfo(res.course_work, 'topic');
      this.loader = false;
    },
      (err) => {
        this.loader = false;
        this.handleError(err);
      });
  }

  prepareCourseworkInfo(course_works, ref = '') {
    let current_time = new Date();
    let list_course_work = course_works;
    let course_work_item: any = null;
    course_work_item = list_course_work.find(item => {
      return item._id == this.courseWorkId;
    });
    if (course_work_item) {
      if (!course_work_item.date) {
        course_work_item['date'] = { day: current_time.getDate(), month: current_time.getMonth() + 1, year: current_time.getFullYear() };
        course_work_item['startDate'] = current_time;
      }
      else {
        course_work_item['startDate'] = new Date(course_work_item.date.year, course_work_item.date.month - 1, course_work_item.date.day);
      }
      this.courseworks = course_work_item;
      this.getCourseWorkteam(this.courseworks);
    }
    // console.log(this.courseData);
    // console.log(this.courseworks);
    this.courseworks_submission = this.courseworks.submission_requirement;
    this.isTeamCreateAllowed();
  }

  getCourseWorkteam(course_works) {
    if (course_works.group_type == 'Student-led' || course_works.group_type == 'manual') {
      if (course_works.team_info.length > 0) {
        this.courseworkTeaminfo = course_works.team_info;
      }
      else {
        this.courseworkTeaminfo = [];
      }
    }
    //FOR ACCEPT REJECT INVITE
    this.checkPedningInvite();
  }

  //FOR ACCEPT REJECT INVITE |START
  checkPedningInvite() {
    this.isPendingInvite = false;
    if ((this.courseworks.group_type == 'Student-led' && this.role == 'student') && !this.isTeachAssistant()) {
      if(this.courseworkTeaminfo.length > 0) {
        this.courseworkTeaminfo.every(courseworkTeam => {
          courseworkTeam.members.every((memberInfo) => {
            if(memberInfo._id == this.loggedInUserId && memberInfo.approval_status == false) {
              this.isPendingInvite = true;
              return false;
            }
            else {
              return true;
            }
          });
        });
      }
    }
  }

  onAcceptReject(actionType, courseworkTeam, member) {
    let teamName = courseworkTeam.name;
    let userId = member._id;
    let response: any = null;
    if(actionType == 'reject') {
      response = false;
    }
    else if(actionType == 'accept') {
      response = true;
    }
    this.inviteProcess = true;
    this.courseService.acceptRejectGroupInvite(this.courseworks.schedule_id, this.courseworks._id, this.courseId, teamName, userId, response).subscribe(response => {
      this.inviteProcess = false;
      this.initializePage();
      this.notificationService.showNotification('success', response.message);
    },
    (error) => {
      this.inviteProcess = false;
      this.initializePage();
      if(error.message) {
        this.notificationService.showNotification('error', error.message);
      }
    });
  }
  //FOR ACCEPT REJECT INVITE |END

  chatWithGroup(cwGroupChat) {
    if (cwGroupChat.user_status != "active") {
      this.notificationService.showNotification('info', 'You are not in this group. Connect with admin');
      return;
    }
    this.router.navigate(['/guftagu'], { queryParams: { group_name: cwGroupChat.name, group_room_id: cwGroupChat.room_id } });
  }

  onGoBack() {
    if(this.notificationParam === true){
      this.router.navigate(['/notifications']);
    } else{
      this.router.navigate(['/courses/single-course'], { queryParams: { courseWork: this.courseWorkId, course_id: this.courseId, course:this.courseNamePram,tab_no:this.activeIdNav} });
    }
  }

  //COURSE WORK CREATE AND EDIT |START
  validateForm(): void {
    this.addTeamByStudnet = this.fb.group({
      name: [""],
      userlist: [""],
    });
  }

  onSelectFile(event) {
    let files = event.target.files;
    //console.log(files);
    if (files.length) {
      const fileSize = files[0].size / 1024 / 1024;
      const limitSize = environment.groupProfilePicSize;
      if (fileSize > limitSize) {
        this.notificationService.showNotification('info', 'File size exceeds ' + limitSize + ' MB');
        return;
      }
      this.uploadGroupPic(files[0]);
    }
  }

  onFileInputClick(event) {
    event.target.value = null;
  }

  uploadGroupPic(file) {
    this.groupProcessing = true;
    this.notificationService.upload_resource('group-photo', file).subscribe((response: HttpEvent<any>) => {
      switch (response.type) {
        case HttpEventType.Response:
          const resourceData = response.body.resource_info[0];
          this.resourcePath = resourceData.path;
          this.uploadedImg = resourceData.url;
          this.groupProcessing = false;
          this.notificationService.showNotification('success', 'File uploaded successfully');
      }
    },
      (err) => {
        this.groupProcessing = false;
        this.notificationService.showNotification('info', 'Please add again not able to save your file');
      });
  }

  openGroupCreateModel(content, formRef, courseworkTeam = {}) {
    this.addedMembers = [];
    this.groupActionName = formRef;
    this.resourcePath = '';
    this.uploadedImg = '';
    this.validateForm();
    this.getSubscribeStudent(formRef);

    //FOR EDIT GROUP
    this.editCourseworkTeam = courseworkTeam;
    if (formRef == 'Edit') {
      this.addTeamByStudnet.controls['name'].setValue(this.editCourseworkTeam.name);
      this.editCourseworkTeam.members.forEach((elm) => {
        this.addedMembers.push(elm);
      });
    }

    this.createGroupModal = this.modalService.open(content, { windowClass: 'createGroupModel', centered: true, size: "xl", backdrop: 'static', keyboard: false });
  }

  closeGroupCreateModel() {
    this.addedMembers = [];
    this.createGroupModal.close();
  }

  discardChanges() {
    this.addedMembers = [];
    this.getSubscribeStudent('Discard');
  }

  getSubscribeStudent(ref = '') {
    let role = 'student';
    this.groupProcessing = true;
    this.courseService.getCourseworkSubscribers(this.courseId, role, this.courseworks.schedule_id).subscribe((response) => {
      this.subscribeStudentList = response.users_info;

      //FOR EDIT GROUP 
      this.initSubscribers(ref);

      //FOR ADD DEFAULT MEMBER
      this.addDefaultMember(ref);

      this.groupProcessing = false;
    }, err => {
      this.groupProcessing = false;
      this.handleError(err);
    });
  }

  initSubscribers(ref) {
    if (ref == 'Edit') {
      this.editCourseworkTeam.members.forEach(element => {
        let subscriberStudent = this.subscribeStudentList.find(subscriber => {
          return subscriber._id == element._id;
        });
        subscriberStudent.isAdded = true;
      });
    }
  }

  addDefaultMember(ref) {
    if (ref == 'Create' || ref == 'Discard') {
      let subscriberStudent = this.subscribeStudentList.find(subscriber => {
        return subscriber._id == this.loggedInUserId;
      });
      this.defaultSubscriber = subscriberStudent;
      this.addMemberToGroup(subscriberStudent);
    }
  }

  addMemberToGroup(subscriberStudent) {
    const index = this.addedMembers.findIndex(addedMember => addedMember._id === subscriberStudent._id);
    if (index == -1) {
      this.addedMembers.push(subscriberStudent);
      subscriberStudent.isAdded = true;
    }
  }

  removeMemberFromGroup(member) {
    const index = this.addedMembers.findIndex(addedMember => addedMember._id === member._id);
    if (index > -1) {
      this.addedMembers.splice(index, 1);
    }
    const indexSubscriber = this.subscribeStudentList.find(subscriberStudent => {
      return subscriberStudent._id === member._id
    });
    if (indexSubscriber) {
      indexSubscriber.isAdded = false;
    }
  }

  saveCourseWorkTeams() {
    const groupName = this.addTeamByStudnet.value.name.trim();

    if (!groupName) {
      this.notificationService.showNotification('info', 'You have not entered any team name please enter it.');
      return;
    }

    if (this.addedMembers.length == 0) {
      this.notificationService.showNotification('info', 'You have not added any member in the group.');
      return;
    }

    const size = this.courseworks.group_size;
    if (this.addedMembers.length > Number(size)) {
      this.notificationService.showNotification('info', 'You have added more than member than group size. Your team size should not exceed: ' + size);
      return;
    }

    //PREPARE POST DATA
    this.resetTeam = false;
    let usersData = [];
    let groupRoomId = '';
    if (this.groupActionName == 'Edit') {
      if (groupName.toLowerCase() == this.editCourseworkTeam.name.toLowerCase()) {
        if (this.editCourseworkTeam.cw_group_chat) {
          groupRoomId = this.editCourseworkTeam.cw_group_chat.room_id;
        }
      }
    }
    this.addedMembers.forEach(element => {
      usersData.push(element._id);
    });
    const finalData = { group_pic: this.resourcePath, group_room_id: groupRoomId, name: groupName, users: usersData };
    //console.log(finalData);
    //return;

    this.groupProcessing = true;
    this.courseService.saveCourseWorkTeams(finalData, this.courseworks.group_type, this.courseworks._id, this.courseworks.schedule_id, this.courseId, 'student-led', this.resetTeam, groupName).subscribe(getTeams => {
      this.notificationService.showNotification('success', getTeams.message);
      this.addedTeam();
      this.groupProcessing = false;
    },
      (error) => {
        this.groupProcessing = false;
        this.notificationService.showNotification('error', error.message);
      });
  }

  addedTeam() {
    this.closeGroupCreateModel();
    this.addedMembers = [];
    this.initializePage();
    this.validateForm();
    this.showCreateGroup = false;
  }

  isTeamCreateAllowed() {
    if ((this.courseworks.group_type == 'Student-led' && this.role == 'student') && (this.courseworks.team_info.length == 0) && !this.isTeachAssistant()) {
      if(!this.isCourseWorkStarted(this.courseworks.date, this.courseworks.time)) {
        this.showCreateGroup = true;
      }
    }
  }

  isCourseWorkStarted(dateValue, time) {
    return this.cwUtilityService.isCourseWorkStarted(dateValue, time);
  }

  isTeachAssistant() {
    let status = false;
    const teachAssisIndex = this.courseData.teach_assis.findIndex(assistant => assistant._id === this.loggedInUserId);
    if (teachAssisIndex != -1) {
      status = true;
    }
    return status;
  }
  //COURSE WORK CREATE AND EDIT |END

  //COURSE WORK SUBMISSION |START
  getExpireDate(dateValue, updateDays) {
    return this.cwUtilityService.getExpireDate(dateValue, updateDays);
  }

  getSubmissionData(data) {
    this.viewSubmissionData = data;
  }

  getReviewSavedStatus(data) {
    this.reviewSavedStatusData = data;
  }
  //COURSE WORK SUBMISSION |END

  openResource(url) {
    window.open(url, "blank")
  }

  handleError(error) {
    console.log(error);
  }

  //FOR BUILD FIX
  searchTexts: string = '';
}
