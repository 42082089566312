import { Component, OnInit, ViewChild } from '@angular/core';
import { QuestionBankService } from 'src/app/services/question-bank.service';
import { debounceTime, distinctUntilChanged, finalize, tap } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { QuestionShareService } from 'src/app/services/question-share.service';
import { QuestionService } from 'src/app/services/question.service';
import { ActivatedRoute, Router } from '@angular/router';
import { intervalToDuration } from 'date-fns';
import { fromEvent } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-question-bank',
  templateUrl: './question-bank.component.html',
  styleUrls: ['./question-bank.component.scss']
})
export class QuestionBankComponent implements OnInit {
  loader = false;
  loader1 = false;
  loader2 = false;
  loader3 = false;  
  searchKey = false;
  isSeached:boolean = false;
  defaultAlert = true;
  // allQues: any[] = [];
  courses: any = [];
  questionModel = null;
  coursesData: string;
  isQusAvailable: boolean = false;
  notPresent: boolean = false;
  qusList: any = [];
  qusTypes: any = [];
  qusTags: any = [];
  csvQues: any = [];
  cols: any = [];
  fileToUpload: File = null;
  importedQues: any = [];
  ques_type: string
  catVal:any
  importQus = false;
  fileName = 'Choose file';

  questionFilter = [
    { name: 'AI Model', value: 'ai_generated' },
    { name: 'Teacher / Admin', value: 'manual' },
    { name: 'Both', value: 'both' }]

    selectedQuetionfilter:string = 'both'
  // FOR PAGINATION
  maxPageNoDisplay: number = 10;
  totalDatalength: any = 0;
  pageSize: number = 20;
  page: any = 1;
  searchText: string = '';
  search_query: string = null;
  search_query_value: string = null;
  search_question_type: string = null;
  search_category: string = null;
  search_category_name_parm: string = null;
  search_category_name: any = [];
  search_page: string = null;
  catNameValue: string = null;
  searchFilter:string = null;

  constructor(private _questionBankService: QuestionBankService,
    private _questionShareService: QuestionShareService,
    private router: Router,
    private actived_Router: ActivatedRoute,
    private _notificationService: NotificationService,
    private modalService: NgbModal,
    ) { }
  @ViewChild('fileInput', { static: false }) fileInput: any; 
  @ViewChild('searchInput', { static: false }) searchInput: any; 

  ngOnInit() {

    //CHECK PERMISSION
    const role = localStorage.getItem('role');
    if (['teacher', 'super_admin'].indexOf(role) == -1) {
      this.router.navigate(['/']);
      return false;
    } 

    this.search_query = this.actived_Router.snapshot.queryParamMap.get('search_query');
    this.search_question_type = this.actived_Router.snapshot.queryParamMap.get('search_question_type');
    this.search_category = this.actived_Router.snapshot.queryParamMap.get('search_category');
    this.search_category_name_parm = this.actived_Router.snapshot.queryParamMap.get('search_category_name');
    this.searchFilter = this.actived_Router.snapshot.queryParamMap.get('searchFilter');
    this.search_page = this.actived_Router.snapshot.queryParamMap.get('search_page');

    if(this.searchFilter !== '' && this.searchFilter !== null && this.searchFilter !== undefined){
      this.selectedQuetionfilter = this.searchFilter;
    }
    if(this.search_question_type !== '' && this.search_question_type !== null && this.search_question_type !== undefined){
      this.ques_type = this.search_question_type;
    }
    if(this.search_category !== '' && this.search_category !== null && this.search_category !== undefined){
      this.catVal = this.search_category;
    }
    if(this.search_page !== '' && this.search_page !== null && this.search_page !== undefined){
      this.page = Number(this.search_page);
    }
    if(this.search_category_name_parm !== '' && this.search_category_name_parm !== null && this.search_category_name_parm !== undefined){
      this.search_category_name.push(this.search_category_name_parm);
      this.catNameValue = this.search_category_name_parm;
    } 
    
    if(this.search_query !== '' && this.search_query !== null && this.search_query !== undefined){
      this.search_query_value = this.search_query;
      this.val = this.search_query;
      this.getQusUsingSearch(this.search_query_value);
    } else{
      this.search_query_value = '';
    }
    this.getAllQusTypes();
    this.getAllCourses();
    this.getAllTags();
  }

  ngAfterViewInit() {
    if(this.searchInput) {
      fromEvent(this.searchInput.nativeElement,'input')
          .pipe(
              //filter(Boolean),
              debounceTime(400),
              distinctUntilChanged(),
              tap((event) => {
                this.page = 1;
                this.searchText = '';
                this.val = this.searchInput.nativeElement.value;
                if(this.searchInput.nativeElement.value.length > 0){
                  this.searchText = this.searchInput.nativeElement.value;
                  this.getQusUsingSearch(this.searchInput.nativeElement.value);
                } else {
                  this.qusList = [];
                  this.isQusAvailable = false;
                }
              })
          )
          .subscribe();
    }    
  }
  
  // FOR PAGINATION HANDLE
  onPageChanged(page: string) {
    this.page = parseInt(page, 10) || 1;
    this.getQusUsingSearch(this.val);
    this.add_query_params_to_actived_route(this.val);
  }

  getQusUsingSearch(val) {
    this.defaultAlert = false;
    this.loader = true;
    if(!this.ques_type){
      this.ques_type = '';
    }
    if(!this.catVal){
      this.catVal = '';
    }
    if(this.val == ''){
      val = ''
    }
    this._questionBankService.fetchQusFilter(val,this.ques_type,this.catVal, this.selectedQuetionfilter, this.page).pipe(finalize(() => {
      //  this.notPresent = false
    })).subscribe(data => {
      this.qusList = [];
      this.totalDatalength = data.total_pages * this.pageSize;
      if (data.data.length > 0) {
        this.isSeached = true;
        this.qusList = data.data;
        this.isQusAvailable = true;
      } 
      this.loader = false;

    });
  }


  val:any
  inputValue: string
  searchQus(ev: any) {

    // set val to the value of the searchbar

    let val = ''
    if (typeof ev == "string") {
      val = ev.toLowerCase();
      this.inputValue = ev.toLowerCase();
    } else {
      val = ev.target.value.toLowerCase();
      this.inputValue = ev.target.value.toLowerCase();
    }
    // if the value is an empty string don't filter the items
    if (val && val.trim() != '') {
      this.val = val
      this.getQusUsingSearch(val);
    } else {
      this.qusList = [];
      this.isQusAvailable = false;
      this.val =''
      this.getQusUsingSearch(this.val);
    }
  }

  editSelectedQues(data) {
    this._questionShareService.setEditQues(data);
    const queryParams = { search_query: String(this.val), ques_id: data._id, search_question_type:this.ques_type,search_category:this.catVal,search_category_name:this.catNameValue,searchFilter:this.selectedQuetionfilter,search_page:this.page };

    this.router.navigate(['/edit-question'], { queryParams: queryParams });
  }

  onStartSearch(val){
    val = val.searchText;
    this.qusList = [];
  
    if (val && val.trim() != '') {
      this.val = val
      this.page = 1; 
      this.getQusUsingSearch(val);
      this.add_query_params_to_actived_route(val);
    } else {
      this.isQusAvailable = false;
      this.val =''
      this.ques_type = '';
      this.catVal = '';
      this.catNameValue = '';
      this.search_category_name = [];
      this.selectedQuetionfilter= 'both'
      this.isSeached = false;
      // this.getQusUsingSearch(this.val);
      // this.add_query_params_to_actived_route(val);
      // this.getQusUsingSearch(this.val);
    }
  }


  add_query_params_to_actived_route(value:string) {
    const queryParams = { search_query: String(value),search_question_type:this.ques_type,search_category:this.catVal,search_category_name:this.catNameValue,searchFilter:this.selectedQuetionfilter,search_page:this.page };
    this.router.navigate([], {
      relativeTo: this.actived_Router,
      queryParams: queryParams,
      queryParamsHandling: 'merge', // Merge with existing query params
    });
  }

  handleFileInput(files) {
    this.fileToUpload = files.target.files[0];
    this.fileName = this.fileToUpload.name;
  }


  onImportQus(ques_type) {
    this._questionBankService.uploadTextQus(this.fileToUpload, ques_type).pipe(finalize(() => {
      this.fileInput.nativeElement.value = '';

    })).subscribe(data => {
      this.csvQues = data.records;
      this._notificationService.showNotification("success", data.message.toUpperCase());
      this.notPresent = true;
      this.isQusAvailable = false;
    },
      (error) => {
        this.fileToUpload = null;
        this.fileName = 'Choose file';
        this._notificationService.showNotification("error", error.message.toUpperCase());
      });
    this.getAllCourses();
  }

  getImportedQus() {
    this.loader1 = true;
    this._questionBankService.fetchImportedQus().pipe(finalize(() => {
    })).subscribe(data => {
      this.importedQues = data.questions;
      this.loader1 = false;
    });
  }

  onImportedQuesChange(eve) {
    if (eve) {
      this.isQusAvailable = true;
      this.notPresent = false;
      this.qusList = this.importedQues;
    } else {
      this.isQusAvailable = false;
    }
  }

  getAllQusTypes() {
    this.loader2 = true;
    this._questionBankService.fetchQusType().pipe(finalize(() => {
    })).subscribe(data => {
      this.qusTypes = data.ques_type;
      this.loader2 = false;
    });
  }

  selectQtype(ques_type, index, type) {
    this.ques_type = ques_type;
    if(this.ques_type == 'Question Type'){
      this.ques_type =''
    }
    this.page = 1;
    this.getQusUsingSearch(this.val);
    this.add_query_params_to_actived_route(this.val);
  }

  onFilterQsn(val:string) {
    this.selectedQuetionfilter = val;
    this.page = 1;
    this.getQusUsingSearch(this.val);
    this.add_query_params_to_actived_route(this.val);
  }

  

  

  onAdded(val) {
   this.catNameValue=val.tag;
   this.catVal=val._id;
   this.page = 1;
   this.getQusUsingSearch(this.val);
   this.add_query_params_to_actived_route(this.val);
  }

  

  removeTag(val){
  if(val._id || (this.catVal && this.catNameValue)){
    this.catVal = ''
    this.catNameValue='';
   this.page = 1;
   this.getQusUsingSearch(this.val);
   this.add_query_params_to_actived_route(this.val);
  }

  }

  get_option(option) {
    let options = option[0].option
    if (options) {
      let splits = options.split("~");
      return splits;
    }
    return ''
  }

  validateOption() {
    if ((this.ques_type == 'singleanswermcq') || (this.ques_type == 'multianswermcq') || (this.ques_type == 'bool')) {
      return true
    }
    return false
  }

  get_answer(answer) {
    let ans = answer.split('~');
    return JSON.stringify(ans);
  }

  get_question_type() {
    if (this.ques_type) {
      let question_type = this.qusTypes.find(type => type.value == this.ques_type);
      return question_type.name;
    }
    return '';
  }
  download_file() {
    let url = '';

    if (this.ques_type == 'text') {
      url = '../assets/import/Question_Text.csv';
    } else if (this.ques_type == 'bool') {
      url = '../assets/import/Question_binary.csv';
    } else if (this.ques_type == 'singleanswermcq') {
      url = '../assets/import/Question_SingleMCQ.csv';
    } else if (this.ques_type == 'fib') {
      url = '../assets/import/Question_FIB.csv';
    } else if (this.ques_type == 'multianswermcq') {
      url = '../assets/import/Question_MCQ.csv';
    } else {
      url = '../assets/import/Question_Text.csv';
    }

    window.open(url, '_blank');
  }

  getAllCourses() {
    this.loader = true;
    this._questionBankService.categoryAPI().pipe().subscribe(data => {
      this.courses = data;
      this.loader = false;
    });
  }

  selectCourseCat(value, csvQues) {
    this.coursesData = value;
    csvQues.category = value;
    if(this.checked) {
      this.checked = false;
    }
  }

  
  checked = false;
  coursesDataCat: any[]
  sameForAll(event) {
    if (!this.coursesData) {
      event.target.checked = !event.target.checked;
      this._notificationService.showNotification('info', 'Please Select the category from the category list');
      return;
    }
    this.checked = false;
    if (event.target.checked) {
      this.checked = true;
      this.csvQues.filter(csvques => {
        csvques.category = this.coursesData
      })
    } else {
      return;
    }
  }

  invalid;
  save_questions() {
    this.invalid = this.csvQues.findIndex(csv => csv.category == undefined);
    if (this.invalid > -1) {
      this._notificationService.showNotification('info', 'Please add the category in every question or make as same for all');
      return;
    }
    this._questionBankService.save_ques(this.csvQues).subscribe(data => {
      this.csvQues = [];
      this._notificationService.showNotification('success', 'Your questions added successfully');
      this.notPresent = false;
      this.fileName = 'Choose file';
      this.fileToUpload = null;
    })
  }

  deletequestionInQb(question_id) {
      this._questionBankService.deleteQuesInQb(question_id).pipe(finalize(() => this.loader = false)).subscribe(res => {
        this._notificationService.showNotification("success", "Question Deleted Successfully");
        this.getQusUsingSearch(this.val);
        this.questionModel.close();
      },
        (err) => {
          this._notificationService.showNotification("error", err.message);
          this.questionModel.close();

        })
    
  }

  // testing code
  editImportedQues(csvQ) {
    this._questionShareService.setEditQues(csvQ);
    this.router.navigate(['/edit-question'], { queryParams: { ques_id: csvQ.user_id } });
  }

  //FOR BUILD FIX
  addQus: any = null;
  submitAddQus: boolean = false;
  courseWorkList: any[] = [];
  
  getPublish(data) {
    return data;
  }

  editSelectedCourseWork(coursework) {
    //
  }

  gotoViewCourseWork(coursework) {
    //
  }

  viewQuestion(id){ 
    const queryParams = { 
      search_query: String(this.val), 
      quesId: id, 
      search_question_type:this.ques_type,
      search_category:this.catVal,
      search_category_name:this.catNameValue,
      searchFilter:this.selectedQuetionfilter,
      search_page:this.page 
    };
    this.router.navigate(['/question-detail'], { queryParams: queryParams });
  }
  
  getAllTags() {
    this.loader3 = true;
    this._questionBankService.fetchTags().pipe(finalize(() => {
    })).subscribe(data => {
      this.qusTags = data.message;
      this.loader3 = false;
    });
  }

  openModelDeleteQuestion(deleteQuestion) {
    this.questionModel = this.modalService.open(deleteQuestion, { size: "sm", centered: true });
  }

  closeQuestionPopup() {
    this.questionModel.close();
    // this.questionId = null;
  }

  addQuestion(){
    this.router.navigate(['/question-bank/add-question']);
  }

  importQuestion(){
    this.router.navigate(['/question-bank/import-question']);
  }
}

