import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { CourseService } from 'src/app/services/course.service';

@Component({
  selector: 'app-question-detail',
  templateUrl: './question-detail.component.html',
  styleUrls: ['./question-detail.component.scss']
})
export class QuestionDetailComponent implements OnInit {

  quesId;
  instanceId;
  resDetails:any;

  loader:boolean;

  // audio/video popup var
  openVideoAudioPopup:boolean = false;

  search_query: string = null;
  search_query_value: string = null;
  search_question_type: string = null;
  search_category: string = null;
  search_category_name_parm: string = null;
  search_category_name: any = [];
  search_page: string = null;
  queryParamsObj:any= {};
  isQuestionDetailPage:boolean = false;
  searchFilter:string = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private courseService:CourseService,
    private router: Router,

  ) { }

  ngOnInit() {
    this.quesId = this.activatedRoute.snapshot.queryParamMap.get('quesId');

    this.isQuestionDetailPage = this.router.url.indexOf('question-detail') !== -1;
    this.search_query = this.activatedRoute.snapshot.queryParamMap.get('search_query');
    this.search_question_type = this.activatedRoute.snapshot.queryParamMap.get('search_question_type');
    this.search_category = this.activatedRoute.snapshot.queryParamMap.get('search_category');
    this.search_category_name_parm = this.activatedRoute.snapshot.queryParamMap.get('search_category_name');
    this.searchFilter = this.activatedRoute.snapshot.queryParamMap.get('searchFilter');
    this.search_page = this.activatedRoute.snapshot.queryParamMap.get('search_page');
    this.questionDetails();

  }

  
questionDetails(){
  this.loader = true
  this.courseService.viewQuestion(this.quesId).pipe(finalize(() => this.loader=false)).subscribe(viewRes => {
      this.resDetails = viewRes.data;
      this.loader = false
    })
  
}

backToQsnBank(){
  if(this.isQuestionDetailPage){
    this.queryParamsObj = { 
      search_query: String(this.search_query), 
      search_question_type:this.search_question_type,
      search_category:this.search_category,
      search_category_name:this.search_category_name_parm,
      searchFilter:this.searchFilter,
      search_page:this.search_page 
    };
    this.router.navigate(['/question-bank'], {queryParams:this.queryParamsObj});
  }
}

goBack() {
  this.router.navigate(['/resource-bank']);
}


openAudioVideoPopup() {
  this.openVideoAudioPopup = true;
}
closeAudioVideoPopup() {
  this.openVideoAudioPopup = false;
}

//FOR BUILD FIX
ansResourceImg: string = '';

}

