<div class="new_UIstyle">
    <div class="banner banner-gradient banner-course-assement">
        <div class="container">
             <div class="banner-content">
                 <div class="banner-content-content">
                     <h2 class="white-text avenirBlack">Attendance</h2>
                 </div>

                 <div class="banner-content-action btn-groups">
                    <button class="btn btn-primary btn-w-120" (click)="backBtn()">Back</button>
                 </div>
             </div>
        </div>
     </div>

    <section class="section attandence-section"  [ngClass]="{submitting : loader}">
        <div class="spinner-border text-warning outer_loader_over" role="status" *ngIf="loader">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="container">
            <div class="attdenceTable"  *ngIf="students?.length > 0">
                <div class="frezecolumns">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="col-120 text-left">Name of Student</th>
                                <th class="col-120 text-left">Username of Student</th>
                                <th class="col-auto">{{topicTitle}} - {{sessionTitle}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let student of students;  index as i">
                                <td class="col-120 text-left">{{student.name | capitalization}}</td>
                                <td class="col-120 text-left">{{student.username}}</td>
                                <td class="col-auto">
                                    <div class="attendanceArea">
                                        <label class="attendance-btn present-btn" ngbTooltip="Present" [ngClass]="{'selected': student.availability === true && student.is_leave === false}">
                                            <input type="radio" name="attendance{{i}}" 
                                                   value="true" 
                                                   [(ngModel)]="student.availability"
                                                   (change)="attendanceBtn(student, true, false, i)" />
                                            P
                                        </label>
                                        <label class="attendance-btn absent-btn" ngbTooltip="Absent" [ngClass]="{'selected': student.availability === false && student.is_leave === false}">
                                            <input type="radio" name="attendance{{i}}" 
                                                   value="false" 
                                                   [(ngModel)]="student.availability"
                                                   (change)="attendanceBtn(student, false, false, i)" />
                                            A
                                        </label>
                                        <label class="attendance-btn leave-btn" ngbTooltip="Leave" [ngClass]="{'selected': student.is_leave === true && student.availability === false}">
                                            <input type="radio" name="attendance{{i}}" 
                                                   value="true" 
                                                   [(ngModel)]="student.is_leave"
                                                   (change)="attendanceBtn(student, false, true, i)" />
                                            L
                                        </label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- if data not found -->
            <div class="alert alert-info text-center" *ngIf="students?.length == 0 && !loader">
                <p>No Data Found.</p>
            </div>
        </div>
    </section>
</div>