import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapitalizationPipe } from '../pipe/capitalization.pipe';
import { CapEachWordPipe } from '../pipe/cap-each-word.pipe';



@NgModule({
  declarations: [
    CapitalizationPipe,
    CapEachWordPipe
  ],
  exports:[
    CapitalizationPipe,
    CapEachWordPipe
  ],
  imports: [
    CommonModule
  ]
})
export class CommonAppModuleModule { }
